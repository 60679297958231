.modal {
  $modal-fade-transform: scale(0.8);

  :global(.modal-dialog) {
    margin: 0;
  }

  :global(.modal-content) {
    transform: rotate(0);
    margin: 0 45px;
    border: 0;
    border-radius: 5px;
  }

  .container {
    padding: 24px 15px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 12px 16px 0 rgba(35, 35, 35, 0.2);
    background-color: #fff;
  }

  :global(.modal-header) {
    display: flex;
    justify-content: center;
    border: 0;
    padding: 0;
    margin: 0 0 2px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.1px;
    text-align: center;
    color: rgba(17, 17, 17, 0.92);
  }

  :global(.modal-body) {
    padding: 0px;
    border-radius: 100px;
  }

  .bodycontainer {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    text-align: center;
    color: rgba(17, 17, 17, 0.84);
  }

  :global(.modal-footer) {
    box-shadow: 0 12px 16px 0 rgba(35, 35, 35, 0.2);
    background-color: #fff;
    padding: 0;
    border-radius: 0 0 5px 5px;
  }

  .button {
    width: 100%;
    border: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    text-align: center;
    background-color: inherit;
    color: #111;
    margin: 0;
    padding: 14px 0;
    border-radius: 0 0 5px 5px;
  }
}
