$white: rgb(255, 255, 255);
$light-grey: rgb(247, 247, 247);
$mid-grey: rgb(216, 216, 216);
$dark-grey: rgb(111, 113, 124);
$charcoal: rgb(129, 129, 129);
$black: rgb(68, 68, 68);

$light-green: rgb(204, 255, 0);

$error-100: rgb(213, 0, 0);
$error-15: rgb(249, 217, 217);
$earnify-color: #2d3680;
