.homeview {
  background-color: #f7f7f7;

  .bp-rewards-heading-message {
    margin: 64px 15px 14px 0;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: #111;
  }

  .bp-rewards-secondary-message {
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.1px;
    color: #111;
    margin-bottom: 32px;

    .link {
      color: #060;
    }
  }

  .rewards_pic {
    height: 208px;
    margin: 24px 0;
    width: 100%;
  }

  .bp-reward-badge {
    border-radius: 4px;
    background-color: #c5e7c1;
    display: flex;
    width: 100%;
    align-items: center;
    margin: 10px 0px;

    .reward-badge-icon {
      margin: 10px 15px;
    }

    .reward-badge-type {
      .green-text-heading {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.78;
        letter-spacing: 0.1px;
        color: #007f00;
      }

      .reward-value {
        font-family: Roboto;
        font-size: 13px;
        line-height: 1.77;
        letter-spacing: 0.2px;
        color: #111;
      }
    }
  }

  &__home-subheading {
    font-size: 2.6rem;
  }

  &__home-external-link {
    font-size: 1.6rem;
    color: rgba(17, 17, 17, 0.84);
    text-decoration: none;
    width: 100%;
    display: block;
    margin-top: 4px;
  }

  &__home-external-link:visited,
  &__home-external-link:hover,
  &__home-external-link:active {
    text-decoration: none;
    color: rgba(17, 17, 17, 0.84);
  }

  &__home-find-participating-btn {
    width: 100%;
    border-radius: 8px;
    border: solid 1px rgba(0, 127, 0, 0.4);
    background-color: #fff;
    color: black;
    font-size: 15px;
    margin-bottom: 0px;
    font-family: Roboto;
    box-shadow: none;
    padding-top: 10px;
    padding-bottom: 10px;

    svg {
      margin-left: 8px;
    }
  }

  &__header {
    padding: 0;
    height: 220px;
    background-color: $black;
  }

  &__loyaltycard_container {
    padding: 0rem 15px ($content-padding * 2) 15px;
    padding-bottom: 24px;
  }

  &__nl_loyalty_card {
    padding-bottom: 24px;
  }

  &__uk_loyalty_card {
    padding-bottom: 32px;
  }

  &__nl_loyalty_card_barcode {
    flex-basis: auto;
  }

  &__left_padding {
    padding-left: $content-padding !important;
  }

  &__right_padding {
    padding-right: $content-padding !important;
  }

  &__barcode_loading {
    margin-top: 43px;
    width: 100%;
    padding: 0;
  }

  &__barcode {
    padding: 10px 0 0 3px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    height: 100px;
    border: 1px solid #ededed;
    align-items: center;
  }

  &__barcode_column {
    flex-basis: auto;
  }

  &__barcode_fullscreen_image {
    background-color: #ffffff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #ededed;
    border-right: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    margin-left: 0;
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
    flex-basis: auto;
  }

  &__barcode_fullscreen_image button {
    box-shadow: none;
    background-color: transparent !important;
  }

  &__barcode_fullscreen_image button:hover {
    box-shadow: none;
    background-color: transparent !important;
  }

  &__barcode_message {
    margin-bottom: 32px;
  }

  &__welcome_message {
    padding-bottom: $content-padding;
  }

  &__points_title {
    height: 24px;
    margin-top: 100px;
    padding: 16px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $white;
  }

  &__payback_promo_header_image_container {
    display: flex !important;
    justify-content: center;
    height: 200px;
    margin-top: 30px;
    background-color: #034ea1;
  }

  &__payback_promo_button {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #006600;
    color: #111111;
  }

  &__uk_header {
    margin-bottom: 24px;
  }

  &__promo_heading {
    margin-bottom: 8px;
  }

  &__uk_promo_content {
    margin-bottom: 0px;
  }

  &__reward_unlocked_text {
    margin-top: 1px;
    margin-bottom: 64px;
  }

  .accordion {

    .card {
      border-top: 0.1rem solid $mid-grey;
      padding: 0 0 0 0;

      &-header {
        padding: 0 0 0 0;
        background-color: $white;
        border: 0;

        padding: 0 0 0 0;

        button {
          text-align: left;
          padding: 16px 0 16px 0;
          text-decoration: none;
          color: $black;
          @include font-medium;
          font-weight: bold;
          background-color: $light-grey;
        }
      }

      &-body {
        padding: 0 0 calc($content-padding / 2) 0;
        background-color: $light-grey;
      }

      &:last-of-type {
        border-bottom: 0.1rem solid $mid-grey;
      }
    }
  }
}
