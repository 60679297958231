.welcomeview {
  &__header {
    padding: 0;
    height: 27rem;
    background-color: $black;
  }

  &__link_loyaltycard_container {
    padding-top: $content-padding;
    padding-bottom: ($content-padding * 2);

    button {
      font-family: Roboto;
      font-size: 15px;
      line-height: 1.33;
      text-align: center;
      color: rgba(255, 255, 255, 0.92);
      border-radius: 8px;
      background-color: #007f00;
    }
  }

  &__signup_loyaltycard_container {
    padding-bottom: $content-padding;

    h2 {
      margin: 0 0 8px;
      font-family: Roboto;
      font-size: 20px;
      line-height: 1.5;
      letter-spacing: 0.15px;
      color: #111;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      white-space: nowrap;
    }

    p {
      margin: 8px 0 24px;
      font-family: Roboto;
      font-size: 16px;
      line-height: 1.75;
      letter-spacing: 0.1px;
      color: #111;

      a {
        display: block;
        padding: 13px 70px;
        border-radius: 8px;
        border: solid 1px #99CC99;
        background-color: #fff;
        font-family: Roboto;
        font-size: 15px;
        line-height: 1.33;
        text-align: center;
        color: rgba(17, 17, 17, 0.84);
        text-decoration: none;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  &__personalizationHeading {
    margin: -100px 0 30px 0;
    color: #fff;
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.1px;
    color: #fff;

    span {
      display: block;
      font-family: Roboto;
      font-size: 29px;
      font-weight: 500;
      line-height: 1.52;
      letter-spacing: 0.1px;
      color: #fff;
    }
  }
}