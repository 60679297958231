.text {
    &--left {
        text-align: left !important;
    }
    &--center {
        text-align: center !important;
    }
    &--right {
        text-align: right !important;
    }
}

.align {
    &--right {
        margin-left: auto !important;
    }
}

$grid-gutter-width: 32px !default;
