/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.prime-loader {
  div {
    border-top: 3px solid var(--color-prime-base) !important;
  }
}

.earnify-loader {
  div {
    border-top: 3px solid var(--color-earnify-base) !important;
  }
}


.loader {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  flex-grow: 0;

  &__justify.row {
    flex-flow: column wrap;
  }

  &__message {
    @include font-small;
    line-height: 2;
    color: $black;
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 60px;
    height: 60px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top: 3px solid var(--color-primary-base);
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 1.2s cubic-bezier(0.3, 0, 0.3, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.18s;
    }

    &:nth-child(2) {
      animation-delay: -0.12s;
    }

    &:nth-child(3) {
      animation-delay: -0.06s;
    }
  }

  &.loader__boxed {
    //only large loader supports a box around
    width: 120px;
    height: 120px;
    border-radius: 90px;
    border: solid 2px $light-grey;
    background-color: $white;

    div {
      margin: 28px;
    }
  }

  &.loader__md {
    width: 32px;
    height: 32px;

    div {
      width: 32px;
      height: 32px;
      border: 2.5px solid transparent;
      border-top: 2.5px solid var(--color-primary-base);
    }
  }

  &.loader__sm {
    width: 16px;
    height: 16px;

    div {
      width: 16px;
      height: 16px;
      border: 2px solid transparent;
      border-top: 2px solid var(--color-primary-base);
    }
  }
}

.loader__fullpage {
  height: calc(100vh - 64px) !important;

  .loader {
    top: -42px;
  }

  .loader__message {
    margin-top: -35px;
  }
}

.prime-spinner-border {
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  width: 1.6rem;
  height: 1.6rem;
  display: inline-block;
  vertical-align: bottom;
  position: absolute;
  top: 16px;
  left: 16px;
}