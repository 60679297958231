.consent-form {
  .form-group {
    padding-top: 1.6rem;
    margin-bottom: 3.2rem;
    font-family: $font-primary;
  }

  .form-sms-group {
    margin-top: -1.6rem;
  }

  .form-check-input {
    width: 24px;
    height: 24px;
    margin: 2px 15px 30px 0;
    border: solid 1px #b6b6b6;
    box-shadow: 0 0 0 1px green inset;

    &.is-invalid {
      box-shadow: 0 0 0 1px $error-100 inset;
    }

    &.border-col {
      box-shadow: 0 0 0 1px $earnify-color inset;
    }

    &.is-invalid~.form-check-label {
      color: $black;
    }
  }

  .form-check-label {
    margin: 0 0 1px 40px;
    font-size: $font-label;
  }

  .invalid-feedback {
    @include font-small;
    margin: 0.4rem 0 0 40px;
  }

  .form-check-help {
    margin-left: 40px;
    display: block;
    color: $charcoal;
    @include font-small;
  }

  small {
    line-height: 1.33;
  }

  .submit-button-earnify {
    background-color: $earnify-color;
  }
}

.submit-button-earnify:active {
  background-color: #2d3680 !important;
  border-color: #2d3680 !important;
}

.prime-consent-container {
  height: 100vh;

  .consentTitle {
    font-size: 30px;
    margin: 0;
    margin-top: 20px;
    line-height: 18px;
  }
  .tancRegistrationHelperText {
    line-height: 20px;
    margin-bottom: 30px;
  }

  .consentSubTitle {
    font-size: 30px;
    margin: 0;
    margin-bottom: 15px;
    color: #6b6767;
    
  }

  .form-group-consents {
    background: #eee;
    padding: 20px;
    border-radius: 10px;
  }

  .consent-form-group,
  .prime-consent-form {
    height: calc(100vh - 174px);

    .form-check-label {
      margin: 0 0 1px 0;
      font-size: $font-label;
    }

    .form-check-tnc {
      margin-top: 6px;
    }

    .form-check-help {
      display: block;
      color: $charcoal;
      @include font-small;
    }
    .prime-consents-submit:active {
      background-color: #000;
      border-color: #000;
    }

    .prime-consents-submit {
      background: #000;
      border-color: #000;
      color: #fff;
      width: 140px;
      border-radius: 35px;
      margin: auto 0;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      margin: auto;

      span.processing {
        margin-left: 10px;
      }
    }

    .form-check-input {
      width: 24px;
      height: 24px;
      margin: 2px 15px 30px 0;
      border: solid 1px #b6b6b6;
      box-shadow: 0 0 0 1px green inset !important;
    }

    /* Customize the label (the container) */
    .form-check-container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .form-check-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .invalid-feedback {
      margin-left: 10px;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 10px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
      border-radius: 5px;
      border: 1px solid #a5a3a3;
    }

    /* On mouse-over, add a grey background color */
    .form-check-container:hover input~.checkmark {
      background-color: #ccc;
    }
    .check-box-label-content {
      margin-left: 10px;
    }
    /* When the checkbox is checked, add a blue background */
    .form-check-container input:checked~.checkmark {
      background-color: #000;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .form-check-container input:checked~.checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .form-check-container .checkmark:after {
      left: 9px;
      top: 4px;
      width: 6px;
      height: 13px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

}