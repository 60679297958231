.header {
  height: 64px;
  border: 0;
  border-bottom: 0px solid rgb(247, 247, 247);
  background-color: #fff;
  gap: 1.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: end;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.primeHeader {
  height: 64px;
  border: 0;
  border-bottom: 0px solid rgb(247, 247, 247);
  background-color: #fff;
  gap: 1.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: end;
  padding: 0 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.headerLogo {
  display: flex;

  .language {
    width: 100%;
    min-width: 140px;
    max-width: 240px;
  }

  .back {
    margin-right: 5px;
    border: none;
    background-color: #fff;
  }
}

.logos {
  display: flex;
  gap: 1.5rem;

  .separator {
    width: 1px;
    background: #ededed;
  }
}

.earnifyLogo {
  display: flex;

  img {
    width: 200px;
  }
}

.bp-logo {
  width: 20px !important;
}

.partnerLogo {
  width: 35px;
}