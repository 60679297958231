.footer {
  border: none;
  background-color: $white;
  width: 100%;
  bottom: 0;
  margin-bottom: 0;
  border-radius: 0;
  padding-left: $content-padding;
  padding-right: $content-padding;

  .list-group-item {
    background-color: $white;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.footer-text {
  @include font-small;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  color: $black;

  &__heading {
    @extend .footer-text;
    font-weight: bold;
    padding-top: 2.4rem;
    padding-bottom: 0px;
  }

  &__subheading {
    @extend .footer-text;
    color: $dark-grey;
    padding-top: 0;
    padding-bottom: 3.2rem;
  }

  &__link {
    @extend .footer-text;
    padding-top: 0;
    padding-bottom: 2rem;

    a {
      color: $black;
      text-decoration: none;
      margin: 0 0 16px;
      font-family: 'Roboto';
      font-size: 13px;
      line-height: 1.77;
      letter-spacing: 0.2px;
      color: #007f00;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__buildnumber {
    @extend .footer-text;
    text-align: right;
    font-size: $font-xs;
    padding-top: 0;
    padding-bottom: 0.8rem;
    color: $charcoal;
  }
}

.footer-textus {
  @include font-small;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  color: $black;

  &__heading {
    @extend .footer-text;
    font-weight: bold;
    padding-top: 2.4rem;
    padding-bottom: 0px;
  }

  &__subheading {
    @extend .footer-text;
    color: $dark-grey;
    padding-top: 0;
    padding-bottom: 3.2rem;
  }

  &__link {
    @extend .footer-text;
    padding-top: 0;
    padding-bottom: 2rem;

    a {
      color: $black;
      text-decoration: none;
      margin: 0 0 16px;
      font-family: 'Roboto';
      font-size: 13px;
      line-height: 1.77;
      letter-spacing: 0.2px;
      color: #2d3680;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__buildnumber {
    @extend .footer-text;
    text-align: right;
    font-size: $font-xs;
    padding-top: 0;
    padding-bottom: 0.8rem;
    color: $charcoal;
  }
}

.footer .list-group-item:last-child {
  padding-bottom: 3.2rem;
}

.footer .container {
  padding: 0;
  max-width: 100%;
}