.nr-accordion__item-header {
  font-size: 18px;
}

.tnc-table {
  font-size: 15px;
}
.policy-container {
  margin-bottom: 20px;
}
.nr-text-component {
  font-size: 15px;
}

.tnc-link {
  color: #000;
  font-weight: 500;
  text-decoration: underline;
}
