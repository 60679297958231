@include keyframes(teapot-dance) {
    0% { left: 0;}
    50%{ left : 100%;}
    100%{ left: 0;}
}

.error_header{
    font-size: 2.3rem;
    line-height: 35px;
    font-weight: normal;
    letter-spacing: 0.15px;
    color: #111;
}

.error_page_btn{
    border-radius: 8px;
    height: 46px;
    border: solid 1px #060;
    background-color: #fff;
    color: #111111;
    font-size: 1.5rem;
}

.btn-earnify-success {
    color: #fff;
    background-color: #2d3680;
}

.error_page_btn_earnify {
    border-radius: 8px;
    height: 46px;
    border: solid 1px #2d3680;
    background-color: #fff;
    color: #111111;
    font-size: 1.5rem;
}

.illustration-error {
    svg {
        width: 220px;
    }
}

.error_container {
    padding: $content-padding;
}
#t-pot-container {
    height: 100px;
}

#t-pot{
    position: absolute;
    -webkit-animation:linear infinite alternate;
    animation: linear infinite alternate;
    -webkit-animation-name: teapot-dance;
    animation-name: teapot-dance;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    @include animation('teapot-dance 5s 99');
}

#marvel-t-pot {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
    &:hover {
        animation: none;
    }
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}
