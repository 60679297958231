.btn {
  &-languageSelector {
    box-shadow: none;
    border: none;
    margin-top: 4px;
    padding: 8px 0;
    &.dropdown-toggle::after {
      display: none;
    }
    &:focus {
      box-shadow: none;
    }
    &:-moz-focusring {
      outline: none;
    }
    &:disabled {
      color: $white !important;
      background-color: $mid-grey !important;
      border-color: $mid-grey !important;
      cursor: not-allowed;
    }
    &:hover,
    &:active {
      &:not([disabled]) {
        background-color: $white !important;
        color: var(--color-primary-dark) !important;
      }
    }
  }
}

svg {
  &.caretDown {
    height:1.2em;
    width:1.2em;
    top: -.1em;
    position: relative;
    display: inline-flex;
    align-self: center;
  }
}

.show {
  .btn {
    svg.caretDown {
      transform: rotate(180deg);
    }
  }
}

.dropdown-menu {
  &.show {
    width: 100%;
    padding: 0;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px $mid-grey;
  }
}

.dropdown-item {
  height: 4.8rem;
  padding: 1.2rem 1rem 1.2rem 1rem;
  font-size: $font-md;
  text-decoration: none;
  &:hover {
    background-color: $light-grey;
  }
  &.active {
    color: $black;
    font-weight: bold;
    background-color: $light-grey;
  }
}

.language-selector-text
{
  border-bottom: 1px solid #111111;
  padding-bottom: 1px;
  font-size: 1.3rem;
  color: #111111;
  margin-right: 8px;
}
