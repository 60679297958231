.row{
    display: flex;
    flex-flow: row wrap;
    flex-basis: 100%;
    box-sizing: border-box;
  }

  @for $width from 1 through 12 {
    .col-#{$width} {
      @include column($width);
    }
  }

  @include breakpoint(mobile) {
    @for $width from 1 through 12 {
      .col-sm-#{$width} {
        @include column($width, $gutter-mobile);
      }
    }
  }

  @include breakpoint(tablet) {
    @for $width from 1 through 12 {
      .col-md-#{$width} {
        @include column($width);
      }
    }
  }

  @include breakpoint(desktop) {
    @for $width from 1 through 12 {
      .col-lg-#{$width} {
        @include column($width);
      }
    }
  }
