p {
  font-family: $font-primary;
  @include font-medium;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  color: $black;
  padding: 0 0 1.6rem 0;
  margin: 0;

  &.bold {
    font-weight: 900;
  }
}