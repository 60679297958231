
.tancRegistrationHeading h1 {
  margin: 16px 0;
  font-family: 'Roboto';
  font-size: 23px;
  line-height: 1.52;
  letter-spacing: 0.15px;
  color: #111;  
  font-style: normal !important;
  font-weight: normal !important;
  padding: 0 !important;
}

.tancHeading h1 {
  margin: 16px 0 0 0;
  font-family: 'Roboto';
  font-size: 20px;
  line-height: 1.52;
  letter-spacing: 0.15px;
  color: #111;  
  font-style: normal !important;
  font-weight: normal !important;
  padding: 0 !important;
}

.tancHeading p {
  margin-top: 16px !important;
  padding: 0 !important;
  font-size: 1.6rem !important;
}
.tancRegistrationHelperText {
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0.1px;
  color: #6b6565;
}
.info-block {
  height: 244px;
  margin: 16px 0 16px;
  padding: 16px 14px 16px 16px;
  border-radius: 4px;
  background-color: #ededed;
  .col {
    padding: 0 10px;
  }
  &__field-label {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.1px;
    color: #111;
    margin-bottom: 5px;
  }
  &__field-value {
    height: 28px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.05px;
    color: #111;
    margin-bottom: 16px;
  }
}