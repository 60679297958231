h1,
h2,
h3 {
  font-stretch: normal;
  font-style: normal;
  color: $black;
  margin: 0;
  padding: 1.6rem 0;
}

h1 {
  font-size: $font-xl;
  font-weight: 100;
  line-height: 1.27; // 36px
  letter-spacing: -0.01rem;
  &.remove-top-padding {
    padding-top: 0;
  }
}

​h2 {
  @include font-large;
  font-weight: 700;
  line-height: 1.13; // 24px
}

​h3 {
  @include font-medium;
  font-weight: 700;
  line-height: 1.5; // 24px
  padding-bottom: 0.4rem;
}

h6 {
  @include font-extra-small;
  font-weight: 400;
  color: $black;
  padding-bottom: 0.4rem;
  margin-bottom: 0;
}

.heading_super {
  font-weight: 700;
  font-size: 1.6rem; // 16px
  padding: 0;
  margin: 0 0 -1.4rem 0;
}
