.btn {
  border: 0;
  border-radius: 2px;
  color: var(--color-primary-dark);
  background-color: $white;
  border-color: var(--color-primary-dark);
  margin: 8px 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.15);
  padding: 16px 0;
  @include font-medium;
  line-height: $font-md;
  &.focus {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
  &-link {
    vertical-align: baseline;
    box-shadow: none;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    color: var(--color-primary-dark);
    border: 0;
    &:hover,
    &:active,
    &:disabled {
      color: var(--color-primary-dark);
      opacity: 1;
    }
    text-decoration: underline;
  }
  &-sm {
    @include font-small;
  }
  &-primary {
    color: $white;
    background-color: var(--color-primary-dark);
    &:hover,
    &:active {
      color: #fff;
      background-color: var(--color-primary-dark) !important;
      border-color: var(--color-primary-dark);
      box-shadow: none;
    }
  }
  &-secondary {
    &:disabled {
      color: $white !important;
      background-color: $mid-grey !important;
      border-color: $mid-grey !important;
      cursor: not-allowed;
    }
    &:hover,
    &:active {
      &:not([disabled]) {
        background-color: $white !important;
        color: var(--color-primary-dark) !important;
      }
    }
  }
  &.likeText {
    border: none;
    background: none;
    color: var(--color-primary-dark);
    box-shadow: none;
    text-decoration: underline;
    padding-top: 0;
    margin-top: 0;
  }
  &.inlineText {
    border: none;
    background: none;
    color: var(--color-primary-dark);
    box-shadow: none;
    text-decoration: underline;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    vertical-align: baseline;
  }
}

.btn-success {
  color: $white;
  background-color: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}

.elevation {
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.15);
}
