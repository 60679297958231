@import '../../styles/1-settings/settings.colors';

.header {
  height: 64px;
  border: 0;
  border-bottom: 0px solid $light-grey;
  background-color: $white;
  gap: 1.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: end;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.primeHeader {
  height: 64px;
  border: 0;
  border-bottom: 0px solid $light-grey;
  background-color: $white;
  gap: 1.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: end;
  padding: 0 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.headerLogo {
  display: flex;

  .language {
    width: 100%;
    min-width: 140px;
    max-width: 240px;
  }

  .back {
    margin-right: 5px;
    border: none;
    background-color: $white;
  }
}

.logos {
  display: flex;
  gap: 1.5rem;

  .separator {
    width: 1px;
    background: #ededed;
  }
}

.earnifyLogo {
  height: 50px;
  display: flex;
  position: relative;
  right: 12px;
}

.partnerLogo {
  width: 35px;
}