@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('../../assets/fonts/Roboto/Roboto-Medium.woff') format('woff'), local('Roboto');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src: url('../../assets/fonts/Roboto/Roboto-Regular.woff') format('woff'), local('Roboto');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/Roboto/Roboto-Light.woff') format('woff'), local('Roboto');
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('../../assets/fonts/Urbanist/Urbanist1.woff2') format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('../../assets/fonts/Urbanist/Urbanist2.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../../assets/fonts/Urbanist/Urbanist3.woff2') format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../../assets/fonts/Urbanist/Urbanist4.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font-primary: 'Roboto', Arial;

/**
 * The base font-size is declared in HTML tag in _generic.normalize.scss
 */

$font-xs: 0.9rem; // 9px
$font-sm: 1.2rem; // 12px
$font-spacing-sm: -0.01rem;
$font-md: 1.6rem; // 16px
$font-spacing-md: -0.015rem;
$font-lg: 1.8rem; // 21.3px
$font-spacing-lg: -0.02rem;
$font-xl: 2.84rem; // 28.4px
$font-spacing-xl: 0.08rem;
$font-label: 1.6rem; // 14px
$font-spacing-label: 0rem;

@mixin font-extra-small {
  font-size: $font-xs;
  line-height: 1.33;
}

@mixin font-small {
  font-size: $font-sm;
  letter-spacing: $font-spacing-sm;
}

@mixin font-medium {
  font-size: $font-md;
  letter-spacing: $font-spacing-md;
}

@mixin font-large {
  font-size: $font-lg;
  letter-spacing: $font-spacing-lg;
}

@mixin font-extra-large {
  font-size: $font-xl;
  letter-spacing: $font-spacing-xl;
}

@mixin font-form-label {
  font-size: $font-label;
  line-height: 2.4rem;
  letter-spacing: $font-spacing-label;
}
