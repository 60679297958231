.bpme-registration-form {
  .row-button {
    padding-top: 3.2rem;
  }

  .row-passcode {
    padding-top: 0.8rem;
  }

  h4 {
    margin: 16px 15px;
    font-family: 'Roboto';
    font-size: 23px;
    line-height: 1.52;
    letter-spacing: 0.15px;
    color: #111;
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
  }

  .invalid-feedback {
    margin-top: 12px;
  }

  .continue-btn {
    border-radius: 8px;
    margin-top: 123px;
  }

  label {
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: #111;
  }

  .resend-btn {
    width: 100%;
    border-radius: 8px;
    border: solid 1px #060;
    background-color: #fff;
    color: #111111;
  }

  button {
    border-radius: 8px;
  }

  p {
    margin: 87px 0 0;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: rgba(17, 17, 17, 0.64);
  }

  small {
    line-height: 1.33;
  }

  .phoneInputSection {
    display: flex;

    .countryCodeSection {
      display: flex;
      height: 46px;
      padding: 11px 16px 11px 12px;
      border: solid 1px #b6b6b6;

      .countryCode {
        margin: 2px 0 2px 4px;
        opacity: 0.8;
        font-family: 'Roboto';
        font-size: 15px;
        line-height: 1.33;
        letter-spacing: 0.2px;
        text-align: center;
        color: #111;
      }

      svg {
        &.ukFlag {
          height: 1.4rem;
          width: 2.0rem;
          position: relative;
          display: inline-flex;
          align-self: center;
        }
      }
    }

    .inputPhoneSection {
      display: flex;
      align-items: center;
      width: 327px;
      height: 46px;
      padding: 0 11px 0 11px;
      border: solid 1px #dedede;

      input {
        padding: 0 86px 0 0;
        font-family: 'Roboto';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0.1px;
        color: rgba(17, 17, 17, 0.64);
      }
    }

    .form-control {
      width: 100%;
      height: 36px;
      border: solid 1px transparent;
      outline: none !important;
      box-shadow: 0 0 1px transparent;
      background-color: transparent;
    }
  }
}