.form-group {
  margin-bottom: 0px;
}

.form-label {
  @include font-form-label;
  margin-bottom: 0.4rem;
  color: $black;
}

.form-control {
  border-color: $charcoal;
  height: 3.6rem;
  letter-spacing: 0.05rem;
  font-size: 1.6rem;
  &.is-invalid {
    box-shadow: 0 0 0 1px $error-100 inset;
    border-color: #dc3545;
  }
}

.text-muted {
  @include font-small;
  line-height: 1.33;
  margin-top: 0.8rem;
}
