.modal {
  @media screen and (orientation: portrait) {
    &__header {
      width: 498px;
    }

    &__body {
      width: 498px;
    }

    &__footer {
      width: 498px;
    }
  }

  &__header {
    height: 88px;
    background-color: #ccff00;
    justify-content: center;
    align-items: center;
  }

  &__header img {
    transform: rotate(90deg);
  }

  &__body {
    height: 124px;
    text-align: center;
    background-color: #ffffff;
  }

  &__footer {
    height: 88px;
    background-color: #ccff00;
    display: block !important;
    text-align: center;
  }

  &__footer .loyaltyNumber {
    padding-bottom: 0 !important;
    font-size: 1.3em !important;
  }

  &__footer .rewards_message {
    padding-bottom: 0 !important;
    font-size: 1.2em !important;
  }

  &__footer .loyaltyNumber {
    letter-spacing: 6px;
  }
}

.modal-header .close {
  display: none;
}

@media screen and (orientation: portrait) {
  .modal-content {
    transform: rotate(270deg);
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  color: $modal-content-color;
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  @include border-radius($modal-content-border-radius);
  @include box-shadow($modal-content-box-shadow-xs);
  // Remove focus outline from opened modal
  outline: 0;
}
