.error-header {
  background-color: $error-15;
  padding: 1.6rem 0 1.6rem 0;
  @include font-small;
  p {
    font-size: 1.2rem;
    line-height: 2;
    padding-bottom: 0;
  }
  svg {
    margin-left: -4px;
  }
}

.error-header-title {
  font-weight: 700;
}
