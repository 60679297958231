.registeredmodal {
  &__header {
    justify-content: center;
    align-items: center;
    border: 0;

    div {
      width: 100%;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.1px;
      color: #111;
      text-align: left;
    }
  }

  &__body {
    text-align: center;
    background-color: #ffffff;

    div {
      text-align: start;

      p {
        padding: 8px 0;
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.77;
        letter-spacing: 0.2px;
        color: #111;
      }
    }
  }

  &__footer {
    justify-content: center;
    text-align: center;
    border: 0;
    padding: 0;

    div {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 !important;

      button:first-child {
        color: #111;

        span {
          margin-right: 5px;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: rgba(17, 17, 17, 0.64);
        border-radius: 0%;
        border-top: 1px solid rgba(35, 35, 35, 0.2);
        box-shadow: none;
        background-color: #fff;
        margin: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .btn-primary:hover,
      .btn-primary:active,
      .btn-primary:focus {
        background-color: #fff !important;
        border-top: 1px solid rgba(35, 35, 35, 0.2);
      }

      .btn-success:hover {
        background-color: #fff !important;
      }

      .btn-success:focus {
        background-color: #fff !important;
      }

      .btn-success:active {
        background-color: #fff !important;
      }
    }
  }

  &__footer .loyaltyNumber {
    padding-bottom: 0 !important;
    font-size: 1.3em !important;
  }

  &__footer .rewards_message {
    padding-bottom: 0 !important;
    font-size: 1.2em !important;
  }

  &__footer .loyaltyNumber {
    letter-spacing: 6px;
  }
}

.modal-header .close {
  display: none;
}

@media screen and (orientation: portrait) {
  .registered-modal {
    .modal-content {
      transform: rotate(0deg);
      width: 75% !important;
      align-self: center;
      border-radius: 5px;
    }
  }

  .modal-dialog-centered {
    justify-content: center;
  }
}
