@mixin breakpoint($screenSize) {
  @if $screenSize == mobile {
    @media (max-width: $breakpoint-mobile-max) {
      @content;
    }
  } @else if $screenSize == tablet {
    @media (min-width: $breakpoint-tablet-min) {
      @content;
    }
  } @else if $screenSize == desktop {
    @media (min-width: $breakpoint-desktop-min) {
      @content;
    }
  }
}

@mixin column($width, $gutter: $gutter-desktop) {
  $one-twelfth: calc(100% / 12);
  $col-width: $one-twelfth * $width;
  flex-basis: calc(#{$col-width} - (#{$gutter} * 2));
  margin: 0 $gutter;
  box-sizing: border-box;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
