.bpmerewardsapp {
  &__logo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    height: 200px;
    background-color: #ccff00;
  }

  &__top_text {
    padding-top: 16px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
