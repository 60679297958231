#diamond {
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-bottom-color: red;
  position: relative;
  top: -50px;
}

#diamond:after {
  content: '';
  position: absolute;
  left: -50px;
  top: 50px;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-top-color: red;
}

.barcode_instructions {
  padding-bottom: 16px;
}

.hero {
  @mixin header {
    padding: 0;
    background-color: $black;
    height: 18rem;
  }

  &__header {
    @include header;
  }

  &__container {
    margin-left: 15px;
    margin-bottom: 24px;
  }

  &__greeting {
    padding-top: $content-padding;
    font-weight: bold;
    @include font-medium;
    color: $white !important;
    padding-bottom: 0px;
  }

  &__firstname {
    font-weight: bold;
    line-height: normal;
    @include font-extra-large;
    color: $white;
    padding-bottom: 0px;
  }

  &__partner_and_tier {
    font-weight: bold;
    padding: calc($content-padding / 2) 0 0 0;
    @include font-small;
    color: var(--color-tertiary-light);
  }

  &__tier_label {
    transform: rotate(45deg);
    height: 11px;
    width: 11px;
    display: inline-block;
    margin-left: 0.8rem;
  }

  &__tier_spacing {
    padding: 2.4rem 1.6rem 0.8rem 1.5rem;
  }

  &__blue {
    background-color: #416de3;
  }

  &__green {
    background-color: #007f00;
  }

  &__gold {
    background-color: #f4c051;
  }

  &__platinum {
    background-color: #8ea3b1;
  }

  &__diamond {
    background-color: #4b525b;
  }

  &__points_number {
    @include font-extra-large;
    line-height: normal;
    padding: 0 0 0 0;
    font-weight: 500;
    display: inline;
    color: #111111;
  }

  &__empty_points_balance {
    margin-bottom: -35px;
  }

  &__points_balance_container {
    margin-bottom: 16px;
    margin-left: -1px;
  }

  &__points_currency_value {
    padding-right: $content-padding;
    @include font-extra-large;
    line-height: 2.4rem;
    display: inline;
    margin-left: 1.6rem;
    color: #111111;
    font-weight: normal;
    font-style: normal;
  }

  &__cpg_loading {
    height: 2.7rem;
    margin-top: calc($content-padding / 2);
    width: $content-padding;
    padding: 1rem 0 0 0;
    margin-bottom: 3.3rem;

    .container {
      padding-left: 0;
    }
  }

  &__point_loading {
    height: $content-padding;
    width: $content-padding;
    padding: $content-padding 0 0 0;
    margin-bottom: 5.1rem;

    .container {
      padding-left: 0;
    }
  }
}
