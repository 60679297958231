.homeview_bp_me_us {
  &__alt_id_container {
    background-color: $light-grey;
    padding-left: $content-padding;
    padding-right: $content-padding;
  }

  &__card {
    background-color: $light-grey;
  }

  &__card_header {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0 !important;
  }

  &__card_border {
    padding-bottom: 16px;
    color: #111111;
  }

  &__container {
    background-color: #f7f7f7;
  }

  &__alt_id_loader {
    padding-top: calc($content-padding / 2);
  }

  &__alt_id_display {
    @include font-large;
    background-color: $white;
    padding-top: 1.5rem;
    padding-bottom: 1.2rem;
    text-align: center;
    border-radius: 0.4rem;
    border: 1px solid #ededed;
    height: 6.2rem;
    box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.08);

  }

  &__alt_id_container_instructions {
    @include font-medium;
    padding-bottom: $content-padding;
  }

  &__alt_id_container_heading {
    @include font-form-label;
    padding-bottom: 0.4rem;
  }

  &__bpmerewards_heading {
    padding-bottom: 0.8rem;
  }

  &__bpmerewards_promo_header_image_container {
    height: 9.6rem;
    width: 17.1rem;
    margin-top: 1.6rem;
  }

  &__left_padding {
    padding-left: $content-padding !important;
  }

  &__right_padding {
    padding-right: $content-padding !important;
  }

  &__bpmerewards_promo {
    height: 12.4rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    margin-bottom: 3.2rem;
  }

  &__bpmerewards_offer {
    height: 12.4rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    border-radius: 0.2rem;
    box-shadow: 0 0.2rem 12rem 0 rgba(0, 0, 0, 0.1), 0 0 1.2rem 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 1.6rem;
    display: table;

    &__loader {
      height: 100%;
      display: table-cell;
      vertical-align: middle;
    }

    &__top_panel {
      background-color: $light-green;
      padding-top: 1.8rem;
      padding-left: 0.8rem;
      padding-right: 0rem;
      padding-bottom: 1.8rem;
      height: 8.4rem;

      &__heart_container {
        padding-right: 1rem;
        padding-left: 1rem;
        display: flex;
        height: 100%;
      }

      &__heart_container svg {
        display: inline-block;
        align-self: flex-end;
      }

      &__text_container {
        display: inline-block;
        padding-left: 0.8rem;
      }

      &__top_text {
        @include font-small;
        font-weight: bold;
        display: flex;
        height: 50%;
        align-items: flex-end;
      }

      &__offer_description {
        @include font-small;
        display: flex;
        height: 50%;
        align-items: flex-end;
      }

      &__bottom_text {
        @include font-large;
        font-weight: bold;
        line-height: 2.4rem;
        color: var(--color-primary-dark);
        display: block;
      }
    }

    &__bottom_panel {
      display: table;
      height: 4rem;

      &__uber_logo {
        height: 3.6rem;
        display: table-cell;
        padding-left: 1.6rem;
        padding-right: 0.8rem;
        vertical-align: middle;
      }

      &__col {
        display: table-cell;
        line-height: 2.4rem;
        @include font-small;
        vertical-align: middle;
        padding-top: 0.4rem;
      }
    }
  }

  .accordion {
    margin-bottom: ($content-padding * 2);

    .card {
      border-top: 0.1rem solid $mid-grey;
      padding: 0 0 0 0;

      &-header {
        padding: 0 0 0 0;
        border: 0;

        padding: 0 0 0 0;

        button {
          text-align: left;
          padding: ($content-padding + calc($content-padding / 2)) 0 ($content-padding + calc($content-padding / 2)) 0;
          text-decoration: none;
          color: $black;
          @include font-medium;
          font-weight: bold;
        }
      }

      &-body {
        padding: 0 0 calc($content-padding / 2) 0;
      }

      &:last-of-type {
        border-bottom: 0.1rem solid $mid-grey;
      }
    }
  }
}

.homeview_bp_me_us__card {
  padding-top: 1.8rem;
  margin-left: -1px;
}

.homeview_bp_me_us__card .col {
  padding-left: 0px;
}

.homeview_bp_me_us__card_header {
  background-color: $light-grey;
}

.homeview_bp_me_us__card .homeview_bp_me_us__card_header .card-header button {
  text-align: left;
  text-decoration: none;
  color: $black;
  @include font-medium;
  font-weight: bold;
  background-color: $light-grey;
}