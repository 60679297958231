.body-wrap {
  background-color: #f7f7f7;
}

h4 {
  font-family: Roboto;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 0.15px;
  color: #111;
}

.ukregistration-form {

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
  }

  .roundButtonCorner {
    border-radius: 8px;
  }

  label {
    margin-top: 16px;
    margin-bottom: 12px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: #111;
  }

  p {
    margin: 87px 0 0;
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: rgba(17, 17, 17, 0.64);
  }

  .form-group {
    margin-bottom: 3.2rem;
    font-family: $font-primary;
  }

  .form-sms-group {
    margin-top: -1.6rem;
  }

  .form-check-input {
    width: 18px;
    height: 18px;
    margin: 0;

    &.is-invalid~.form-check-label {
      color: rgba(17, 17, 17, 0.5);
    }

  }

  .form-check-label {
    margin: 0 0 0.4rem 2.6rem;
    font-size: $font-label;
    font-weight: 300;
    color: rgba(17, 17, 17, 0.5);
  }

  .invalid-feedback {
    @include font-small;
    margin: 0.4rem 0 0 0;
  }

  .form-check-help {
    margin-left: 26px;
    display: block;
    color: $charcoal;
    @include font-small;
  }

  small {
    line-height: 1.33;
  }

  .phoneInputSection {
    display: flex;

    .countryCodeSection {
      display: flex;
      height: 46px;
      padding: 11px 16px 11px 12px;
      border: solid 1px #b6b6b6;

      .countryCode {
        margin: 2px 0 2px 4px;
        opacity: 0.8;
        font-family: Roboto;
        font-size: 15px;
        line-height: 1.33;
        letter-spacing: 0.2px;
        text-align: center;
        color: #111;
      }

      svg {
        &.ukFlag {
          height: 1.4rem;
          width: 2.0rem;
          position: relative;
          display: inline-flex;
          align-self: center;
        }
      }

      &.is-invalid {
        box-shadow: 0 0 0 1px $error-100;
        border: solid rgba(255, 255, 255, .5);
      }
    }

    .inputPhoneSection {
      display: flex;
      align-items: center;
      width: 327px;
      height: 46px;
      padding: 0 11px 0 11px;
      border: solid 1px #dedede;

      input {
        padding: 0 36px 0 0;
        font-family: Roboto;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0.1px;
        color: rgba(17, 17, 17, 0.64);
      }

      &.is-invalid {
        box-shadow: 0 0 0 1px $error-100;
        border: solid rgba(255, 255, 255, .5);
      }
    }

    .form-control {
      width: 100%;
      height: 36px;
      border: solid 1px transparent;
      outline: none !important;
      box-shadow: 0 0 1px transparent;
      background-color: transparent;
    }
  }
}

.ukverify-form {

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
  }

  .invalid-feedback-red {
    color: #dc3545 !important;
    font-size: 1.2rem;
  }

  .invalid-feedback {
    margin-top: 12px;
  }

  .continue-btn {
    border-radius: 8px;
    margin-top: 123px;
  }

  label {
    margin-top: 16px;
    margin-bottom: 12px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: #111;
  }

  .resend-btn {
    width: 100%;
    border-radius: 8px;
    border: solid 1px #060;
    background-color: #fff;
    color: #111111;
  }

  button {
    border-radius: 8px;
  }

  p {
    margin: 87px 0 0;
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: rgba(17, 17, 17, 0.64);
  }

  small {
    line-height: 1.33;
  }

  .phoneInputSection {
    display: flex;

    .countryCodeSection {
      display: flex;
      height: 46px;
      padding: 11px 16px 11px 12px;
      border: solid 1px #b6b6b6;

      .countryCode {
        margin: 2px 0 2px 4px;
        opacity: 0.8;
        font-family: Roboto;
        font-size: 15px;
        line-height: 1.33;
        letter-spacing: 0.2px;
        text-align: center;
        color: #111;
      }

      svg {
        &.ukFlag {
          height: 1.4rem;
          width: 2.0rem;
          position: relative;
          display: inline-flex;
          align-self: center;
        }
      }
    }

    .inputPhoneSection {
      display: flex;
      align-items: center;
      width: 327px;
      height: 46px;
      padding: 0 11px 0 11px;
      border: solid 1px #dedede;

      input {
        padding: 0 86px 0 0;
        font-family: Roboto;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0.1px;
        color: rgba(17, 17, 17, 0.64);
      }
    }

    .form-control {
      width: 100%;
      height: 36px;
      border: solid 1px transparent;
      outline: none !important;
      box-shadow: 0 0 1px transparent;
      background-color: transparent;
    }
  }
}