ul {
  li {
    @include font-medium;
  }
}

hr.empty {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 0;
  padding-bottom: 8px;
  line-height: 0px;
}
