.error-registration {
  @include font-small;
  margin-top: 16px;
  border-width: 1px;
  border-color: #ff7591;
  background-color: #ffedf0;
  border-radius: 3px;

  p {
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding-bottom: 0;
    letter-spacing: 0.15px;

    a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid black;
      cursor: pointer;
    }
  }

  svg {
    display: block;
    margin: 0 auto;
  }
}

.bpme-registration-form label.passcode-msg-wording {
  margin-top: 1rem;
  @include font-small;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.64);
}
