.linkform-section {
  margin: 1.6rem auto;
}

.invalid-feedback {
  @include font-small;
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
  display: block;
}

.grey-bordered-input {
  border: 1px solid #dedede !important;
}

.form-control {
  border: 1px solid #dedede;
}

.btn {
  border-radius: 8px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.33;
}

.btn-success {
  color: rgba(255, 255, 255, 0.92);
  background-color: #007F00;
}

.btn-secondary {
  border-radius: 8px;
  border: solid 1px #99CC99;
  background-color: #fff;
}